import DatePicker from "component/common/DatePicker";
import RightOpenPop from "component/common/RightOpenPop";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { MdKeyboardArrowDown } from "react-icons/md";
import ReactQuill from "react-quill";
import { optionLocation } from "utils/Constants";
import PdfPreview from "./PdfPreview";

const GenerateLetter = ({ setGenerateLetter, jobDetails }) => {
  const { t } = useTranslation();
  const [locationOpen, setLocationOpen] = useState(false);
  const [preview, setPreview] = useState(false);
  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic"],
      ["link"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { list: "check" },
        { script: "sub" },
        { script: "super" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      [{ align: [] }],
      [{ color: [] }, { background: [] }],
      ["clean"],
      ["blockquote", "image"],
    ],

    clipboard: {
      matchVisual: false,
    },
  };

  const handleLocationOpen = () => {
    setLocationOpen(!locationOpen);
  };

  const handlePreview = () => {
    setPreview(true);
  };

  return (
    <>
      <RightOpenPop
        cancelText={t("Preview")}
        saveText={t("save")}
        onClose={() => setGenerateLetter(false)}
        previewIcon={true}
        handlePreview={handlePreview}
      >
        <>
          {preview && <PdfPreview setPreview={setPreview} />}

          <div className="h-full max-h-[83vh] overflow-y-auto custom_scroll">
            <div className="px-5 py-2">
              <div className="flex flex-col gap-4">
                <hi className="font-semibold text-[1rem]">
                  {t("ContactInfo")}
                </hi>
                <div className="grid xl:grid-cols-2 gap-3">
                  <div>
                    <label className="text-[#686868] text-[0.9rem]">
                      {t("candidateName")}
                    </label>
                    <h2 className="font-medium text-[1.1rem]">
                      {jobDetails?.jobFullName}
                    </h2>
                  </div>
                  <div>
                    <label className="text-[#686868] text-[0.9rem]">
                      {t("email")}
                    </label>
                    <h2 className="font-medium text-[1.1rem]">
                      {jobDetails?.email}
                    </h2>
                  </div>
                  <div>
                    <label className="text-[#686868] text-[0.9rem]">
                      {t("state")}
                    </label>
                    <h2 className="font-medium text-[1.1rem]">
                      {jobDetails?.state}
                    </h2>
                  </div>
                  <div>
                    <label className="text-[#686868] text-[0.9rem]">
                      {t("country")}
                    </label>
                    <h2 className="font-medium text-[1.1rem]">
                      {jobDetails?.country}
                    </h2>
                  </div>
                </div>
              </div>
            </div>
            <hr className="mt-2" />
            <div className="px-5 py-2 h-full">
              <div className="flex flex-col gap-3">
                <hi className="font-semibold text-[1rem]">{t("comBenifit")}</hi>
                <div className="flex flex-col gap-3">
                  <div>
                    <label className="font-semibold text-[1.2rem]">
                      {t("jobTitle")}
                    </label>
                    <input
                      className="border w-full h-12 rounded-md p-2"
                      value={jobDetails?.jobName}
                      disabled
                    />
                  </div>
                  <div>
                    <label className="font-semibold text-[1.2rem]">
                      {t("letterType")}
                    </label>
                    <select
                      type="number"
                      name="jobType"
                      id="jobType"
                      className="h-[2.625rem] w-full border rounded
                  p-[10px_14px_10px_10px] text-[#191919] bg-white"
                    >
                      <option value="">{t("select")}</option>
                      <option value="Work From Office">
                        {t("offerLetter")}
                      </option>
                      <option value="">{t("Other")}</option>
                    </select>
                  </div>
                  <div>
                    <label className="font-semibold text-[1.2rem]">
                      {t("ctc")}
                    </label>
                    <input className="border w-full h-12 rounded-md p-2" />
                  </div>
                  <div>
                    <label className="font-semibold text-[1.2rem]">
                      {t("employeeType")}
                    </label>
                    <select
                      type="number"
                      name="jobType"
                      id="jobType"
                      className="h-[2.625rem] w-full border rounded
                  p-[10px_14px_10px_10px] text-[#191919] bg-white"
                    >
                      <option value="">{t("select")}</option>
                      <option value="full time">{t("fullTime")}</option>
                      <option value="part time">{t("partTime")}</option>
                      <option value="remote">{t("Remote")}</option>
                    </select>
                  </div>
                  <div>
                    <label className="font-semibold text-[1.2rem]">
                      {t("deductions")}
                    </label>
                    <input className="border w-full h-12 rounded-md p-2" />
                  </div>
                  <div>
                    <label className="font-semibold text-[1.2rem]">
                      {t("onBoardingDate")}
                    </label>
                    <DatePicker
                      name="date"
                      min={new Date().toISOString().split("T")[0]}
                      className="h-[2.625rem] w-full"
                    />
                  </div>
                  <div>
                    <label className="font-semibold text-[1.2rem]">
                      {t("noticePeriod")}
                    </label>
                    <input className="border w-full h-12 rounded-md p-2" />
                  </div>
                  <div>
                    <label className="font-semibold text-[1.2rem]">
                      {t("probationPeriod")}
                    </label>
                    <input className="border w-full h-12 rounded-md p-2" />
                  </div>
                  <div className="flex flex-col space-y-2 relative">
                    <label htmlFor="location" className="text-[#313135]">
                      {t("preLocation")}
                    </label>
                    <div
                      className="h-[2.625rem] w-full border border-[#E2E8F0] rounded
                   p-[10px_14px_10px_10px] text-[#191919] bg-white cursor-pointer relative"
                      name="location"
                      onClick={handleLocationOpen}
                    >
                      <div className="absolute top-3 font-bold right-0">
                        <MdKeyboardArrowDown className="text-[20px]" />
                      </div>
                    </div>
                    {locationOpen && (
                      <div className="absolute w-full top-[4.1rem] rounded-[0.5rem] py-2 px-4 bg-white border border-[#DEE4EB] z-10">
                        {optionLocation?.map((item) => {
                          return (
                            <>
                              <div className="bg-white z-30 flex items-center gap-2 py-1">
                                <input
                                  type="checkbox"
                                  className="cursor-pointer border bg-white rounded-[10rem] w-[1.2rem] h-[1.2rem]"
                                />
                                <p value={item?.value}>{item?.value}</p>
                              </div>
                            </>
                          );
                        })}
                      </div>
                    )}
                  </div>
                  <div>
                    <label className="font-semibold text-[1.2rem]">
                      {t("description")}
                    </label>
                    <ReactQuill
                      className="w-full h-fit"
                      placeholder="Content"
                      style={{ color: "#000000", minHeight: "100px" }}
                      modules={modules}
                      theme="snow"
                    />
                  </div>
                  <div>
                    <label className="font-semibold text-[1.2rem]">
                      {t("offeredBy")}
                    </label>
                    <input className="border w-full h-12 rounded-md p-2" />
                  </div>
                  <div>
                    <label className="font-semibold text-[1.2rem]">
                      {t("signatureBy")}
                    </label>
                    <input className="border w-full h-12 rounded-md p-2" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      </RightOpenPop>
    </>
  );
};

export default GenerateLetter;
