import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaChevronLeft } from "react-icons/fa";

const PdfPreview = ({ setPreview }) => {
  const { t } = useTranslation();

  const [pages, setPages] = useState([]);

  useEffect(() => {
    const splitContentIntoPages = (content) => {
      const pageHeight = 500;
      const pageContent = [];
      let currentPage = [];
      let currentHeight = 0;

      content.split("\n").forEach((line) => {
        const lineHeight = 20;
        if (currentHeight + lineHeight > pageHeight) {
          pageContent.push(currentPage);
          currentPage = [line];
          currentHeight = lineHeight;
        } else {
          currentPage.push(line);
          currentHeight += lineHeight;
        }
      });

      if (currentPage.length > 0) {
        pageContent.push(currentPage);
      }

      return pageContent;
    };

    const dummyContent = `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus laoreet leo quis lorem gravida, sed scelerisque nunc faucibus. Nullam tincidunt orci ac tortor lobortis scelerisque. Etiam at neque at elit tincidunt ultricies. Nam fringilla erat eu metus venenatis, et fermentum nisl tempor. Integer nec nunc et purus cursus auctor ut et justo. Curabitur vehicula feugiat eros, non aliquet purus egestas vitae. Nulla sit amet consectetur odio, sit amet varius risus.
    Cras nec eros est. Suspendisse potenti. Quisque vel eros ligula. Etiam vel nunc leo. Sed in tincidunt arcu. Curabitur auctor erat a nisi cursus gravida. Sed fermentum elit ac sapien vehicula, non varius sem aliquet. Phasellus laoreet viverra lectus, ac gravida risus pharetra non. Phasellus pharetra interdum erat, vel gravida velit aliquet a. Sed a dictum risus. Etiam mollis magna a sem tincidunt gravida. Mauris vitae volutpat libero. 

    Donec hendrerit sit amet dui nec maximus. Aliquam erat volutpat. Integer tempus arcu metus, et cursus dolor rutrum at. Cras interdum, sem ac faucibus cursus, odio risus tempor tortor, sit amet tincidunt nisi lorem vel lorem. Aenean at vestibulum purus, id condimentum lectus. Integer eu leo sed ante interdum fermentum sit amet ut ligula. Nunc ac metus non sem facilisis malesuada sed in libero.

    Aenean nec sapien orci. Integer et libero sollicitudin, iaculis justo nec, consequat ante. Nam at enim sed eros iaculis feugiat. Nam viverra tincidunt erat, vel viverra purus volutpat eget. Donec gravida quam vel erat facilisis, eu vestibulum felis aliquam. Nam efficitur venenatis velit ac scelerisque. Curabitur sit amet cursus lacus. Nam mollis sapien sed diam vestibulum volutpat. Sed et varius libero. Sed sollicitudin sapien quis libero tincidunt, et dictum odio condimentum.

    Praesent finibus interdum turpis, in eleifend erat placerat nec. Aenean varius interdum mi, ac fermentum purus sollicitudin vel. Nam a enim sit amet urna pharetra tincidunt. Mauris molestie, urna in mollis tincidunt, lorem lorem interdum ante, vel malesuada nisi felis eu nunc. In tincidunt dui vitae leo maximus gravida. Aliquam erat volutpat.
    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus laoreet leo quis lorem gravida, sed scelerisque nunc faucibus. Nullam tincidunt orci ac tortor lobortis scelerisque. Etiam at neque at elit tincidunt ultricies. Nam fringilla erat eu metus venenatis, et fermentum nisl tempor. Integer nec nunc et purus cursus auctor ut et justo. Curabitur vehicula feugiat eros, non aliquet purus egestas vitae. Nulla sit amet consectetur odio, sit amet varius risus.
    Cras nec eros est. Suspendisse potenti. Quisque vel eros ligula. Etiam vel nunc leo. Sed in tincidunt arcu. Curabitur auctor erat a nisi cursus gravida. Sed fermentum elit ac sapien vehicula, non varius sem aliquet. Phasellus laoreet viverra lectus, ac gravida risus pharetra non. Phasellus pharetra interdum erat, vel gravida velit aliquet a. Sed a dictum risus. Etiam mollis magna a sem tincidunt gravida. Mauris vitae volutpat libero. 

    Donec hendrerit sit amet dui nec maximus. Aliquam erat volutpat. Integer tempus arcu metus, et cursus dolor rutrum at. Cras interdum, sem ac faucibus cursus, odio risus tempor tortor, sit amet tincidunt nisi lorem vel lorem. Aenean at vestibulum purus, id condimentum lectus. Integer eu leo sed ante interdum fermentum sit amet ut ligula. Nunc ac metus non sem facilisis malesuada sed in libero.

    Aenean nec sapien orci. Integer et libero sollicitudin, iaculis justo nec, consequat ante. Nam at enim sed eros iaculis feugiat. Nam viverra tincidunt erat, vel viverra purus volutpat eget. Donec gravida quam vel erat facilisis, eu vestibulum felis aliquam. Nam efficitur venenatis velit ac scelerisque. Curabitur sit amet cursus lacus. Nam mollis sapien sed diam vestibulum volutpat. Sed et varius libero. Sed sollicitudin sapien quis libero tincidunt, et dictum odio condimentum.

    Praesent finibus interdum turpis, in eleifend erat placerat nec. Aenean varius interdum mi, ac fermentum purus sollicitudin vel. Nam a enim sit amet urna pharetra tincidunt. Mauris molestie, urna in mollis tincidunt, lorem lorem interdum ante, vel malesuada nisi felis eu nunc. In tincidunt dui vitae leo maximus gravida. Aliquam erat volutpat.

    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus laoreet leo quis lorem gravida, sed scelerisque nunc faucibus. Nullam tincidunt orci ac tortor lobortis scelerisque. Etiam at neque at elit tincidunt ultricies. Nam fringilla erat eu metus venenatis, et fermentum nisl tempor. Integer nec nunc et purus cursus auctor ut et justo. Curabitur vehicula feugiat eros, non aliquet purus egestas vitae. Nulla sit amet consectetur odio, sit amet varius risus.
    Cras nec eros est. Suspendisse potenti. Quisque vel eros ligula. Etiam vel nunc leo. Sed in tincidunt arcu. Curabitur auctor erat a nisi cursus gravida. Sed fermentum elit ac sapien vehicula, non varius sem aliquet. Phasellus laoreet viverra lectus, ac gravida risus pharetra non. Phasellus pharetra interdum erat, vel gravida velit aliquet a. Sed a dictum risus. Etiam mollis magna a sem tincidunt gravida. Mauris vitae volutpat libero. 

    Donec hendrerit sit amet dui nec maximus. Aliquam erat volutpat. Integer tempus arcu metus, et cursus dolor rutrum at. Cras interdum, sem ac faucibus cursus, odio risus tempor tortor, sit amet tincidunt nisi lorem vel lorem. Aenean at vestibulum purus, id condimentum lectus. Integer eu leo sed ante interdum fermentum sit amet ut ligula. Nunc ac metus non sem facilisis malesuada sed in libero.

    Aenean nec sapien orci. Integer et libero sollicitudin, iaculis justo nec, consequat ante. Nam at enim sed eros iaculis feugiat. Nam viverra tincidunt erat, vel viverra purus volutpat eget. Donec gravida quam vel erat facilisis, eu vestibulum felis aliquam. Nam efficitur venenatis velit ac scelerisque. Curabitur sit amet cursus lacus. Nam mollis sapien sed diam vestibulum volutpat. Sed et varius libero. Sed sollicitudin sapien quis libero tincidunt, et dictum odio condimentum.

    Praesent finibus interdum turpis, in eleifend erat placerat nec. Aenean varius interdum mi, ac fermentum purus sollicitudin vel. Nam a enim sit amet urna pharetra tincidunt. Mauris molestie, urna in mollis tincidunt, lorem lorem interdum ante, vel malesuada nisi felis eu nunc. In tincidunt dui vitae leo maximus gravida. Aliquam erat volutpat.
    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus laoreet leo quis lorem gravida, sed scelerisque nunc faucibus. Nullam tincidunt orci ac tortor lobortis scelerisque. Etiam at neque at elit tincidunt ultricies. Nam fringilla erat eu metus venenatis, et fermentum nisl tempor. Integer nec nunc et purus cursus auctor ut et justo. Curabitur vehicula feugiat eros, non aliquet purus egestas vitae. Nulla sit amet consectetur odio, sit amet varius risus.
    Cras nec eros est. Suspendisse potenti. Quisque vel eros ligula. Etiam vel nunc leo. Sed in tincidunt arcu. Curabitur auctor erat a nisi cursus gravida. Sed fermentum elit ac sapien vehicula, non varius sem aliquet. Phasellus laoreet viverra lectus, ac gravida risus pharetra non. Phasellus pharetra interdum erat, vel gravida velit aliquet a. Sed a dictum risus. Etiam mollis magna a sem tincidunt gravida. Mauris vitae volutpat libero. 

    Donec hendrerit sit amet dui nec maximus. Aliquam erat volutpat. Integer tempus arcu metus, et cursus dolor rutrum at. Cras interdum, sem ac faucibus cursus, odio risus tempor tortor, sit amet tincidunt nisi lorem vel lorem. Aenean at vestibulum purus, id condimentum lectus. Integer eu leo sed ante interdum fermentum sit amet ut ligula. Nunc ac metus non sem facilisis malesuada sed in libero.

    Aenean nec sapien orci. Integer et libero sollicitudin, iaculis justo nec, consequat ante. Nam at enim sed eros iaculis feugiat. Nam viverra tincidunt erat, vel viverra purus volutpat eget. Donec gravida quam vel erat facilisis, eu vestibulum felis aliquam. Nam efficitur venenatis velit ac scelerisque. Curabitur sit amet cursus lacus. Nam mollis sapien sed diam vestibulum volutpat. Sed et varius libero. Sed sollicitudin sapien quis libero tincidunt, et dictum odio condimentum.

    Praesent finibus interdum turpis, in eleifend erat placerat nec. Aenean varius interdum mi, ac fermentum purus sollicitudin vel. Nam a enim sit amet urna pharetra tincidunt. Mauris molestie, urna in mollis tincidunt, lorem lorem interdum ante, vel malesuada nisi felis eu nunc. In tincidunt dui vitae leo maximus gravida. Aliquam erat volutpat.
    `;

    setPages(splitContentIntoPages(dummyContent));
  }, []);

  return (
    <div className="w-full h-full flex items-center justify-end fixed top-0 left-0 z-40 bg-[rgba(3,27,89,.2)]">
      <div className="min-w-[50%] w-full h-full bg-white flex flex-col space-y-8 shadow-[0_0px_20px_0px_rgba(3,27,89,0.10)] max-w-[60%] transition-all">
        <div className="flex flex-col justify-between h-full">
          <div>
            <div className="w-full p-5 bg-white flex justify-between border border-b">
              <div className="flex justify-center items-center font-extrabold gap-5">
                <div
                  className="cursor-pointer"
                  onClick={() => setPreview(false)}
                >
                  <FaChevronLeft />
                </div>
                <h2 className="font-extrabold text-xl text-[#031B59]">
                  {t("preview")}
                </h2>
              </div>
            </div>

            <div className=" h-[80vh] overflow-y-auto">
              {pages.map((page, index) => (
                <div key={index} className="content-section bgPreviewImg m-4">
                  <div className="bg-section-text">
                    {page.map((line, lineIndex) => (
                      <p key={lineIndex}>{line}</p>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PdfPreview;
